<template>
  <div
    class="fccc"
    @mousemove="hover = true"
    @mouseleave="hover = false"
  >
    <div class="loader"></div>
    <div class="loadText">
      <span><span class="loadText_TF">T</span>RANS</span>
      <span><span class="loadText_TF">F</span>ACT</span>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "LoadAnimation",
  data() {
    return {
      hover: false,
    };
  },
};
</script>
  
  <style  scoped>
.loadText {
  margin: 1em 0px;
  font-size: max(1em, 2vw);
  font-weight: bold;
  letter-spacing: 10px;
  color: rgba(var(--white));
  animation: loadingDots 7s steps(6) infinite;
}

.loadText_TF{
 color: rgb(255, 255, 255); 
 animation: loadText_TF 1s 2s forwards;
}

@keyframes loadText_TF {
  0%{
    color: rgb(255, 255, 255); 
  }
  100%{
    color: rgb(255, 165, 0)
  }
}

/* 创建一个名为.loader的样式类 */
.loader {
  /* 设置宽度为50像素 */
  width: 90px;
  /* 设置宽高比为1.154，用于创建一个特定比例的形状 */
  aspect-ratio: 1.154;
  /* 设置相对定位，以便后续绝对定位的元素相对于其进行定位 */
  position: relative;
  /* 创建一个渐变背景，从圆锥渐变，起始角度为120度，颜色变化为黑色和红色 */
  background: conic-gradient(
    from 120deg at 50% 64%,
    rgba(var(--black), 0),
    rgba(var(--background-default)) 1deg 120deg,
    rgba(var(--black), 0) 121deg
  );
  /* 应用动画效果，持续1.5秒，无限循环，使用cubic-bezier函数定义的缓动效果 */
  animation: l27-0 3s infinite cubic-bezier(0.3, 1, 0, 1);
}

.loader_noshow:hover {      /* 将类名改成loader，即可显示hover的效果 */
  background: conic-gradient(
    from 120deg at 50% 64%,
    rgba(var(--black), 0),
    rgba(var(--background-default),1) 1deg,
    /* 在此处加入一个稍暗的阴影或不同的颜色 */ rgba(var(--background-default),1) 120deg,
    rgba(var(--black), 0) 121deg
  );
  animation: l27-0 0s infinite cubic-bezier(0.3, 1, 0, 1);
}

/* 定义.loader的伪元素:before和:after */
.loader:before,
.loader:after {
  /* 设置内容为空 */
  content: "";
  /* 设置为绝对定位，填充父元素的尺寸 */
  position: absolute;
  /* 填充整个父元素的区域 */
  inset: 0;
  /* 继承.loader的背景样式 */
  background: inherit;
  /* 设置变换原点为元素底部中心 */
  transform-origin: 50% 66%;
  /* 应用动画效果，持续1.5秒，无限循环 */
  animation: l27-1 3s infinite;
}

/* 定义.loader的:after伪元素 */
.loader:after {
  /* 设置一个自定义属性，用于在动画中改变值的方向 */
  --s: -1;
}

/* 定义名为l27-0的关键帧动画 */
@keyframes l27-0 {
  /* 在0%到30%之间的时间段 */
  0%,
  30% {
    /* 将元素旋转角度设置为0度 */
    transform: rotate(0);
  }

  /* 在70%时间点 */
  70% {
    /* 将元素旋转角度设置为120度 */
    transform: rotate(120deg);
  }

  /* 从70.01%到100%之间的时间段 */
  70.01%,
  100% {
    /* 将元素旋转角度设置为360度 */
    transform: rotate(360deg);
  }
}

/* 定义名为l27-1的关键帧动画 */
@keyframes l27-1 {
  /* 在0%时间点 */
  0% {
    /* 将元素按照--s变量定义的角度旋转并平移 */
    transform: rotate(calc(var(--s, 1) * 120deg)) translate(0);
  }

  /* 在30%和70%时间点 */
  30%,
  70% {
    /* 将元素按照--s变量定义的角度旋转并向左上方移动一定距离 */
    transform: rotate(calc(var(--s, 1) * 120deg))
      translate(calc(var(--s, 1) * -10px), 20px);
  }

  /* 在100%时间点 */
  100% {
    /* 将元素按照--s变量定义的角度旋转并归位 */
    transform: rotate(calc(var(--s, 1) * 120deg)) translate(0);
  }
}
</style>