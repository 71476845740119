<!-- 设置了blue和 white两种样式的按钮-->

<template>
  <div :class="['button', buttonClass]" @click="navigate">
    <slot></slot>
    <!-- 插槽接受文本内容-->
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
export default {
  name: "ButtonCommon",
  props: {
    variant: {
      type: String,
      defalut: "white",
    },
    to:{
        type:String,
        required:false,
    }
  },
  setup(props){
    const router = useRouter();
    const navigate = () =>{
        if(props.to){
            router.push(props.to)
        }
    };
    return { navigate };
    
  },
  computed: {
    buttonClass() {
  if (this.variant === "blue") {
    return "button-blue";
  } else if (this.variant === "grey") {
    return "button-grey";
  } else {
    return "button-white";
  }
}
  },
};
</script>

<style scoped>
.button {
  position: relative;
  display: inline-block;
  width: auto;
  height: auto;
  padding: 0.4em 1.3em;
  border-radius: 2em;
  text-transform: uppercase; /* 字体默认大写 */
  letter-spacing: 0.1em;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.2s;
}

.button:hover {
  transform: translateY(-3px);
}

.button:active {
  transform: translateY(-1px);
}

.button-white {
  background-color: var(--white-rgb);
  color: rgb(var(--primary-default));
}

.button-grey {
  background-color: rgb(var(--background-default));
  color: rgb(var(--white));
  opacity: 0.8;
}

.button-white:hover {
  box-shadow: 0 5px 1px rgba(var(--white), 0.2);
}

.button-white:active {
  box-shadow: 0 3px 1px rgba(var(--white), 0.4);
}

.button-blue {
  background-color: rgb(var(--primary-default));
  color: var(--white-rgb);
}

.button-blue:hover {
  box-shadow: 0 5px 1px rgba(var(--black), 0.2);
}

.button-blue:active {
  box-shadow: 0 3px 1px rgba(var(--black), 0.4);
}
</style>