import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import * as IconPart from '@icon-park/vue-next'
import gsap from 'gsap'
import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/saga-blue/theme.css'; // 选择适合你项目的主题
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import './universalCSS/index.css'
import VueParticles from 'vue-particles';

// import 'typeface-lato'

const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }

  for (const [key, component] of Object.entries(IconPart)) {
    app.component(key, component)
  }

app.use(router);
app.use(ElementPlus);
app.use(PrimeVue);
app.config.globalProperties.$gsap = gsap;
app.use(VueParticles);
app.mount('#app');

// 禁用缩放的代码
// function disableZoom() {
//   document.addEventListener('wheel', function(event) {
//     if (event.ctrlKey === true) {
//       event.preventDefault();
//     }
//   }, { passive: false });

//   document.addEventListener('keydown', function(event) {
//     if ((event.ctrlKey === true && (event.keyCode === 61 || event.keyCode === 107)) || 
//         (event.ctrlKey === true && (event.keyCode === 173 || event.keyCode === 109)) || 
//         (event.ctrlKey === true && event.keyCode === 187) || 
//         (event.ctrlKey === true && event.keyCode === 189)) {
//       event.preventDefault();
//     }
//   }, false);

//   document.addEventListener('touchstart', function(event) {
//     if (event.touches.length > 1) {
//        event.preventDefault();
//     }
//   }, { passive: false });

//   let lastTouchEnd = 0;
//   document.addEventListener('touchend', function(event) {
//     const now = (new Date()).getTime();
//     if (now - lastTouchEnd <= 300) {
//       event.preventDefault();
//     }
//     lastTouchEnd = now;
//   }, false);
// }

// disableZoom();
