<template>
  <div class="body">
    <div class="fccc backgroundImg">
      <Load></Load>
      <div class="frcc">
        <Button variant="white" to="/webMain">开始浏览</Button>
        <div style="width: 1em"></div>
        <el-tooltip
          effect="dark"
          content="尚未完成，敬请期待"
          placement="top"
        >
          <Button variant="grey" class="buttonDisable">引导式</Button>
        </el-tooltip>
      </div>
    </div>
    <div class="companyInfo">
      <div class="fccc" v-if="!showdata">
        <p style="font-weight: 600; letter-spacing: 2px">
          苏州传思法特信息科技有限公司
        </p>
        <p style="font-size: 0.5em; margin-top: 0.2em">
          Transfact WEB v1.1_240829
        </p>
      </div>
      <div class="fccc" v-if="showdata">
        <p style="font-weight: 600; letter-spacing: 2px">{{ companyName }}</p>
        <p style="font-size: 0.5em; margin-top: 0.2em">{{ emailAddress }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Load from "@/components/common/Load.vue";
import Button from "@/components/common/Button.vue";
export default {
  name: "HomePage",
  data() {
    return {
      inputString: "",
      keySequence: "",
      showdata: false,
      companyName: "总经理 杨洋",
      emailAddress: "yang.yang@transfact.de",
      timeout: null
    };
  },
  components: {
    Load,
    Button,
  },
  methods: {
    handleKeydown(event) {
      this.inputString += event.key;
      this.keySequence += event.key.toLowerCase();

      // 检查是否匹配 "sh"
      if (this.keySequence.includes("sh")) {
        this.$router.push("/TransFact2");
        this.keySequence = "";
        clearTimeout(this.timeout);
      } else {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.keySequence = "";
        }, 500);
      }

      if (this.inputString.includes("111")) {
        console.log("keydown", this.inputString);
        this.inputString = ""; // 匹配后重置字符串
        this.showdata = true;
      }

      // 如果字符串太长，重置以避免内存问题
      if (this.inputString.length > 100) {
        this.inputString = "";
      }
    },
    decodeString(encodedStr) {
      return encodedStr
        .split("\\u")
        .map((part) => String.fromCharCode(parseInt(part, 16)))
        .join("");
    },
  },

  mounted() {
    window.addEventListener("keydown", this.handleKeydown);
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.handleKeydown);
  },
  updated() {
    console.log("keydown", this.inputString);
  },
};
</script>

<style scoped>
.body {
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: rgb(var(--background-default));
}

.backgroundImg {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to right bottom,
    rgba(var(--secondary-default), 1) 0%,
    rgba(var(--primary-default), 1) 100%
  );
  clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
  justify-content: center;
  align-items: center;
}

.companyInfo {
  position: absolute;
  bottom: 2%;
  right: 2%;
  color: rgb(var(--primary-default));
  font-size: 1em;
}
</style>
