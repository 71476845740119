import { createRouter, createWebHistory } from 'vue-router'
import HomePage from './HomePage/HomePage.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage
  },
  { path: '/index.html', redirect: '/' },

  {
    path: '/webMain',
    name: 'webMain',
    component: () => import(/* webpackChunkName: "about" */ '../views/WebMain.vue'),
    children:[
      {
        path: '',
        name: 'FrontPage',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Front/FrontPage.vue')
    
      },
      

      {
        path: 'product',
        name: 'product',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Product/ProductPage.vue')
    
      },
      {
        path: 'product1',
        name: 'product1',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Product/ProductPage copy.vue')
    
      },
      {
        path: 'productPrice',
        name: 'productPrice',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Product/ProductPrice.vue')
    
      },
      {
        path: 'case',
        name: 'case',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/IndustryCase/CasePage.vue')
    
      },
      {
        path: 'process',
        name: 'process',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Process/ProcessPage.vue')
    
      },
      {
        path: 'support',
        name: 'support',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Support/SupportPage.vue'),
        children:[
          {
            path: '',
            name: 'SupportPage00',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "about" */ '../views/Support/SupportPage00.vue')
        
          },
    
          {
            path: 'SupportPage01',
            name: 'SupportPage01',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "about" */ '../views/Support/SupportPage01.vue')
        
          },
          {
            path: 'SupportPage02',
            name: 'SupportPage02',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "about" */ '../views/Support/SupportPage02.vue')
        
          },
        ]
    
      },
      {
        path: 'test',
        
        name: 'test',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/Test/TestPage.vue')
    
      },
    
    ]

  },

  {
    path: '/TransFact2',
    name: 'TransFact2',
    component: () => import(/* webpackChunkName: "about" */ '../views/SandBox/TransFact2.vue'),
    children:[
      {
        path: '',
        name: 'tf2homePage',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/SandBox/TF2HomePage.vue'),
        children:[
          {
            path: '',
            name: 'tf2matrix',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "about" */ '../views/SandBox/TF2Matrix.vue')
         
          },
          {
            path: 'bar/:lineName',
            name: 'barView',
            component: () => import(/* webpackChunkName: "about" */ '../views/SandBox/TF2CompletionRate.vue'),
            props: true // 启用 props，使得路由参数可以作为 props 传入组件
          },
          {
            path: 'detail/:lineName',
            name: 'DetailView',
            component: () => import(/* webpackChunkName: "about" */ '../views/SandBox/TF2DetailView.vue'),
            props: true // 启用 props，使得路由参数可以作为 props 传入组件
          },
    
        
        ]
    
      },

      {
        path: 'TF2product',
        name: 'TF2product',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/SandBox/TF2Product.vue')
    
      },
      {
        path: 'TF2APS',
        name: 'TF2APS',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/SandBox/APS/TF2APS.vue')
    
      },
      {
        path: 'TF2APS_Project',
        name: 'TF2APS_Project',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/SandBox/APS_Project/TF2APS.vue')
    
      },
      {
        path: 'TF2APS_DM',
        name: 'TF2APS_DM',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/SandBox/APS_DM/TF2APS.vue')
    
      },
      {
        path: 'TF2APS_TM',
        name: 'TF2APS_TM',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/SandBox/APS_TM/TF2APS.vue')
    
      },
      {
        path: 'TF2Machine',
        name: 'TF2Machine',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/SandBox/TF2MachineMonitor.vue')
    
      },
     

      

    
    ]
  },

  {
    path: '/TFchatGPT',
    name: 'TFchatGPT',
    component: () => import(/* webpackChunkName: "about" */ '../views/SandBox/TFchatGPT.vue'),
  },

  
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(),
  // history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
